import MCPlayer from './player'
import videojs from 'video.js'

require('@silvermine/videojs-quality-selector')(videojs)

var MCVideoPlayer = function(container, options) {
  MCPlayer.call(this, container, options)
}

MCVideoPlayer.get = function(id) {
  return MCPlayer.get(id)
}

MCVideoPlayer.prototype = $.extend({}, MCPlayer.prototype, {
  type: 'video',
  defaultOptions: {
    autoRender: true,
    controls: true,
    preload: 'auto',
    videoSrcTmpl: '',
    assetTracks: [],
    assetVideos: [],
    trackEngagement: true,
    sessionEventId: '',
    cssClasses: 'video-js vjs-default-skin',
  },

  initialize: function(options) {
    this.__renderOnHold = false
    this.__resolutionsLoaded = false

    this.template = options.isProcessing ? '<div class="video-js-box asset-processing" style="background: rgba(0, 0, 0, .65) url(${posterUrl})"><p>Asset is currently being processed.<br>Check back shortly.</p></div>' :
      '<div class="video-js-box">' +
      '<video id="${id}" class="${cssClasses}" data-setup=\'{"fluid": true}\' {{if controls}} controls{{/if}}{{if autoplay}} autoplay muted {{/if}} preload="${preload}" poster="${posterUrl}">' +
      '{{each assetVideos}}' +
      '{{if slug != "mp4-high" &&  type == "video/mp4"}}' +
      '<source src="${src}" type="${type}" data-res="${res}" label="${label}" />' +
      '{{/if}}' +
      '{{/each}}' +
      '{{each assetTracks}}' +
      '<track kind="${assetTrackType}" src="${assetTrackURL}" srclang="${langSlug}" label="${langName}">' +
      '{{/each}}'

    this.options.plugins = $.extend({}, options.plugins || {})

    if ('videoUrl' in this.options && this.options.videoUrl) {
      var assetVideos = [
        {
          slug: '',
          type: 'video/mp4',
          src: this.options.videoUrl,
          res: '',
        },
      ]
      this.options.assetVideos = assetVideos
    }

    if (this.options.autoRender) {
      this.render()
    }

    this.loadResolutions()
  },

  render: function() {
    var self = this
    if (!self.__resolutionsLoaded) {
      self.__renderOnHold = true
    } else {
      self.__renderOnHold = false
      self.$container.append($.tmpl(self.template, self.options))

      const player = videojs(
        self.id,
        {
          plugins: self.options.plugins,
        },
        function() {
          self.onBackendReady(this)
        }
      )

      if (this.options.assetVideos.length > 1) {
        player.controlBar.addChild('QualitySelector', {}, 9)
      }
    }
  },

  loadResolutions: function() {
    this.__resolutionsLoaded = false
    $.ajax({
      type: 'POST',
      url: '/api/public/getasseturls',
      data: {
        assetKey: this.options.assetKey,
      },
      dataType: 'json',
    }).done($.proxy(this.onResolutionsDone, this))
  },

  onResolutionsDone: function(response) {
    this.__resolutionsLoaded = true
    if (response.success) {
      this.options = $.extend(this.options, response)
    }
    if (this.__renderOnHold) {
      this.render()
    }
  },
  destroy: function() {
    this.backend.dispose()
  },
})

export default MCVideoPlayer
